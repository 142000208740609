<!-- 实时统计 -->
<template>
  <section class="cont realTimeStatistics">
    <Breadcrumb :crumbs="crumbs" />
    <el-row class="content-box">
      <div class="card-box">
        <div class="card-box-item">
          <p class="item-tit">新增用户</p>
          <p class="item-tit m-top-10 m-bottom-20">
            <span>今日</span>
            <span class="m-left-10">{{ today }}</span>
          </p>
          <p class="item-text">{{ todayCount }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit">累积新增用户</p>
          <p class="item-tit m-top-10 m-bottom-20">
            <span>至今日</span>
            <span class="m-left-10">{{ today }}</span>
          </p>
          <p class="item-text">{{ count }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit">小程序活跃用户数</p>
          <p class="item-tit m-top-10 m-bottom-20">
            <span>今日</span>
            <span class="m-left-10">{{ today }}</span>
          </p>
          <p class="item-text">{{ wechatToday }}人</p>
        </div>
        <div class="card-box-item">
          <p class="item-tit">小程序累积访问数</p>
          <p class="item-tit m-top-10 m-bottom-20">
            <span>至今日</span>
            <span class="m-left-10">{{ today }}</span>
          </p>
          <p class="item-text">{{ wechatCount }}人</p>
        </div>
      </div>
    </el-row>
    <div class="m-top-20 chart-box">
      <el-col class="content-box chart-item">
        <p class="m-bottom-10">分时新增用户</p>
        <span>今日</span>
        <span class="m-left-10">{{ today }}</span>
        <CurveChart class="charBox" name="新增用户" :xAxis="addUser_x" :series="addUser_y" chartId="addUser" ref="addUser"></CurveChart>
      </el-col>
      <el-col class="content-box chart-item">
        <p class="m-bottom-10">分时老用户活跃</p>
        <span>今日</span>
        <span class="m-left-10">{{ today }}</span>
        <CurveChart class="charBox" name="老用户活跃" :xAxis="oldUser_x" :series="oldUser_y" chartId="oldUser" ref="oldUser"></CurveChart>
      </el-col>
    </div>
    <div class="m-top-20 chart-box">
      <el-col class="content-box chart-item">
        <p class="m-bottom-10">分时活跃用户</p>
        <span>今日</span>
        <span class="m-left-10">{{ today }}</span>
        <CurveChart class="charBox" name="活跃用户" :xAxis="activeUser_x" :series="activeUser_y" chartId="activeUser" ref="activeUser"></CurveChart>
      </el-col>
      <el-col class="content-box chart-item">
        <p class="m-bottom-10">分时访问次数</p>
        <span>今日</span>
        <span class="m-left-10">{{ today }}</span>
        <CurveChart class="charBox" name="访问次数" :xAxis="visit_x" :series="visit_y" chartId="visit" ref="visit"></CurveChart>
      </el-col>
    </div>
  </section>
</template>

<script>
  import { mapState } from 'vuex'
  import { dateFactory } from '@/common/js/common'
  import { getMemberHotelTotal } from '@/api/business/userStatistics/realTimeStatistics'
  export default {
    name: 'realTimeStatistics',
    data() {
      return {
        crumbs: new Map([['业务中台'], ['实时用户统计'], ['实时统计']]),
        today: dateFactory.getDistanceToday(0, false),
        todayCount: 0,    // 新增用户
        count: 0,         // 累积新增用户
        wechatToday: 0,   // 小程序活跃用户数
        wechatCount: 0,   // 小程序累积访问数

        addUser_x: [],    // 分时新增用户 X轴
        addUser_y: [],    // 分时新增用户 Y轴

        oldUser_x: [],    // 分时老用户活跃 X轴
        oldUser_y: [],    // 分时老用户活跃 Y轴

        activeUser_x: [], // 分时活跃用户 X轴
        activeUser_y: [], // 分时活跃用户 Y轴

        visit_x: [],      // 分时访问次数 X轴
        visit_y: [],      // 分时访问次数 Y轴
      }
    },
    computed: {
      ...mapState(['hotelInfo']),
    },
    mounted() {
      this.getData()
    },
    methods: {
      getData() {
        const data = {
          companyId: this.hotelInfo.storeId , // 集团ID
        }
        getMemberHotelTotal(data).then(({ success, records }) => {
          if (success) {
            this.todayCount = records.todayCount
            this.count = records.count
            this.wechatToday = records.wechatToday
            this.wechatCount = records.wechatCount

            // 新增用户
            for (let i = 0; i < records.hourMemberCount.length; i++) {
              this.addUser_x[i] = records.hourMemberCount[i].date + '时'
              this.addUser_y[i] = records.hourMemberCount[i].count
            }
            this.$refs.addUser.drawChart()

            // 老用户活跃
            for (let i = 0; i < records.evertMemberHotelCount.length; i++) {
              this.oldUser_x[i] = records.evertMemberHotelCount[i].date + '时'
              this.oldUser_y[i] = records.evertMemberHotelCount[i].total
            }
            this.$refs.oldUser.drawChart()

            // 活跃用户
            for (let i = 0; i < records.evertMemberHotelCount.length; i++) {
              this.activeUser_x[i] = records.evertMemberHotelCount[i].date + '时'
              this.activeUser_y[i] = records.evertMemberHotelCount[i].total + records.hourMemberCount[i].count
            }
            this.$refs.activeUser.drawChart()

            // 访问次数
            for (let i = 0; i < records.visitCount.length; i++) {
              this.visit_x[i] = records.visitCount[i].date + '时'
              this.visit_y[i] = records.visitCount[i].total
            }
            this.$refs.visit.drawChart()
          }
        })
      },
    },
  }
</script>

<style lang="scss" scoped>
  .realTimeStatistics {
    .card-box {
      $gradient-blue: linear-gradient(90deg, #006fff, #189aff);
      $gradient-green: linear-gradient(90deg, rgb(78, 211, 181), rgb(41, 231, 188));
      $gradient-org: linear-gradient(90deg, rgb(245, 108, 108), rgb(244, 127, 64));
      $gradient-yellow: linear-gradient(90deg, rgb(255, 161, 50), rgb(255, 209, 50));
      $gradient-red: linear-gradient(90deg, rgb(163, 50, 255), rgb(151, 99, 233));
      $list-bg: $gradient-blue, $gradient-green, $gradient-org, $gradient-yellow, $gradient-red;

      display: flex;
      justify-content: space-between;
      .card-box-item {
        width: 22%;
        padding: 30px 40px;
        background-repeat: no-repeat;
        text-align: left;
        background-position: 0 0;
        background-size: 100% 100%;
        box-sizing: border-box;
        color: #ffffff;
        border-radius: 6px;
        @each $c in $list-bg {
          $i: index($list-bg, $c);
          &:nth-child(#{$i}) {
            background: nth($list-bg, $i);
          }
        }
        .item-tit {
          font-size: 18px;
          font-weight: 400;
        }
        .item-text {
          font-size: 36px;
          font-weight: 700;
        }
      }
    }
    .chart-box {
      display: flex;
      font-size: 18px;
      justify-content: space-between;
      .chart-item {
        width: calc(50% - 10px);
        .charBox {
          width: 100%;
          height: 300px;
        }
      }
    }
  }
</style>
